import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
	maxToasts: 5,
  timeout: 10000,
});

import ApiPlugin from './plugins/api';
Vue.use(ApiPlugin);

import network from '@/api/network'
network.configure();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
