import network from "@/api/network";

export default class Usercard {
  static async getUserCard(user_id, project_id) {
    return await network.get(`user/${user_id}/project/${project_id}/profile`, null, {});
  }

  static async moveDataFromUser(data) {
    return await network.post("user/relations/change", data, {});
  }

  static async addPoints(data) {
    return await network.post("points/user/add", data, {});
  }

  static async subPoints(data) {
    return await network.post("points/user/subtract", data, {});
  }
}
