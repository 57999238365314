import network from "@/api/network";

export default class Members {
  static async getUsers(group, page, perPage, data) {
    return await network.post(`user/list/promo/group/${group}/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async addUser(data) {
    return await network.post('user/participant', data, {});
  }

  static async modifyUser(data) {
    return await network.put('user', data, {});
  }

  static async deleteUser(data) {
    return await network.delete('user', data, {});
  }

  static async exportUsers(group, data, config) {
    return await network.post(`user/list/promo/group/${group}/filter/download/report`, data, config);
  }

  static async getSegments() {
    return await network.get('company/segment/list', null, {});
  }

  static async getCuratorInnList(id) {
    return await network.get(`user/curator/${id}/export/inn/members`, null, {});
  }

  static async updateActualAddress(data) {
    return await network.post("user/company/actual/address/import", data, {});
  }
}