import network from "@/api/network";

export default class POS {
  static async getPOSList(page, perPage, data) {
    return await network.get(`pos-materials/?page=${page}&per_page=${perPage}`, data, {});
  }

  static async modifyPOSItem(data) {
    return await network.post('pos-materials', data, {});
  }

  static async deletePOSItem(data) {
    return await network.delete('pos-materials', data, {});
  }

  static async getCharacteristics() {
    return await network.get('pos-materials/characteristics/types', null, {});
  }
}